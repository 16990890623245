export * from './amazonSellerAccounts'

// Account Settings Constants
export const PENDING_ACCOUNT_ACTION = 'PENDING_ACCOUNT_ACTION'
export const COMPLETED_ACCOUNT_ACTION = 'COMPLETED_ACCOUNT_ACTION'
export const PENDING_CREATING_ACCOUNT_ACTION = 'PENDING_CREATING_ACCOUNT_ACTION'
export const COMPLETED_CREATING_ACCOUNT_ACTION =
  'COMPLETED_CREATING_ACCOUNT_ACTION'
export const RESET_ACCOUNT_ACTION = 'RESET_ACCOUNT_ACTION'
export const COMPLETED_LOAD_ACCOUNT = 'COMPLETED_LOAD_ACCOUNT'
export const COMPLETED_BILLING_ACTION = 'COMPLETED_BILLING_ACTION'

export const ACCOUNT_INPUT_FIELD_UPDATE = 'ACCOUNT_INPUT_FIELD_UPDATE'
export const ACCOUNT_DROPDOWN_FIELD_UPDATE = 'ACCOUNT_DROPDOWN_FIELD_UPDATE'
export const ADD_ACCOUNT_PROFILE_VALIDATION_ERROR =
  'ADD_ACCOUNT_PROFILE_VALIDATION_ERROR'
export const RESET_USER_INFO = 'RESET_USER_INFO'

export const TOGGLE_AUTHY_SIGNUP_MODAL = 'TOGGLE_AUTHY_SIGNUP_MODAL'
export const AUTHY_INPUT_FIELD_UPDATE = 'AUTHY_INPUT_FIELD_UPDATE'

export const ACCOUNT_PHOTO_UPLOADED = 'ACCOUNT_PHOTO_UPLOADED'
export const ACCOUNT_PHOTO_DELETED = 'ACCOUNT_PHOTO_DELETED'

export const TOGGLE_CHECK_INVOICE = 'TOGGLE_CHECK_INVOICE'
export const TOGGLE_CHECK_ALL_INVOICES = 'TOGGLE_CHECK_ALL_INVOICES'
export const RESET_CHECKED_INVOICES = 'RESET_CHECKED_INVOICES'
export const INVOICE_INPUT_FIELD_UPDATE = 'INVOICE_INPUT_FIELD_UPDATE'
export const SET_ACTIVE_INVOICE = 'SET_ACTIVE_INVOICE'

export const TOGGLE_REVOKE_USER_CONFIRM_MODAL =
  'TOGGLE_REVOKE_USER_CONFIRM_MODAL'

export const ACCOUNT_SET_LANGUAGE = 'ACCOUNT_SET_LANGUAGE'

export const UNBIND_ALIPAY_LOADING = 'UNBIND_ALIPAY_LOADING'
export const BIND_ALIPAY_LOADING = 'BIND_ALIPAY_LOADING'
export const UPDATE_THEME = 'UPDATE_THEME'
export const ENABLE_CNTHEME = 'ENABLE_CNTHEME'

export const UPDATE_USER_INFO = 'UPDATE_USER_INFO'
