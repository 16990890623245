/*
 *               _
 *              | |
 *           ___| |_ ___  _ __
 *          / __| __/ _ \| '_ \
 *          \__ \ || (_) | |_) |
 *          |___/\__\___/| .__/
 *                       | |
 *                       |_|
 *
 * Please do not add to this file.
 * Additions to this file will get pulled into the main JS bundle where they probably don't need to be
 * Please create a new file, add your methods/constants to that and import just the code you need.
 *
 * For more info see https://github.com/Junglescout/docs/blob/master/client/Code-Splitting.md
 */

// Niche / Keyword Constant
export const PENDING_KEYWORD_SEARCH = 'PENDING_KEYWORD_SEARCH'
export const FAILED_KEYWORD_SEARCH = 'FAILED_KEYWORD_SEARCH'
export const COMPLETED_KEYWORD_SEARCH = 'COMPLETED_KEYWORD_SEARCH'
export const SORT_KEYWORDS = 'SORT_KEYWORDS'

export const SET_KEYWORD_FILTER = 'SET_KEYWORD_FILTER'
export const SET_KEYWORD_MIN_MAX_FILTER = 'SET_KEYWORD_MIN_MAX_FILTER'
export const SET_KEYWORD_COUNTRY = 'SET_KEYWORD_COUNTRY'
export const SET_KEYWORD_CATEGORIES = 'SET_KEYWORD_CATEGORIES'
export const SET_KEYWORD_PAGE = 'SET_KEYWORD_PAGE'
export const RESET_KEYWORD_FILTERS = 'RESET_KEYWORD_FILTERS'

export const PENDING_KEYWORD_PRESET = 'PENDING_KEYWORD_PRESET'
export const SET_KEYWORD_PRESETS = 'SET_KEYWORD_PRESETS'
export const LOAD_KEYWORD_PRESET = 'LOAD_KEYWORD_PRESET'
export const SELECT_KEYWORD_PRESET = 'SELECT_KEYWORD_PRESET'
export const UPDATE_KEYWORD_PRESET_NAME = 'UPDATE_KEYWORD_PRESET_NAME'
export const TOGGLE_KEYWORD_SAVE_PRESET = 'TOGGLE_KEYWORD_SAVE_PRESET'
export const TOGGLE_KEYWORD_LOAD_PRESET = 'TOGGLE_KEYWORD_LOAD_PRESET'
export const TOGGLE_KEYWORD_DELETE_PRESET = 'TOGGLE_KEYWORD_DELETE_PRESET'

// Opportunity Finder
export const FIRST_OPPORTUNITY_SEARCH = 'FIRST_OPPORTUNITY_SEARCH'
export const TOGGLE_EXCLUDE_TOP_BRANDS = 'TOGGLE_EXCLUDE_TOP_BRANDS'
export const TOGGLE_KEYWORD_MULTIUSER_SHARING =
  'TOGGLE_KEYWORD_MULTIUSER_SHARING'

// Product Database Constants
export const SET_PRODUCT_DATABASE_DATA = 'SET_PRODUCT_DATABASE_DATA'
export const PENDING_PRODUCT_SEARCH = 'PENDING_PRODUCT_SEARCH'
export const FAILED_PRODUCT_SEARCH = 'FAILED_PRODUCT_SEARCH'
export const COMPLETED_PRODUCT_SEARCH = 'COMPLETED_PRODUCT_SEARCH'
export const PENDING_PRODUCT_VARIANTS_SEARCH = 'PENDING_PRODUCT_VARIANTS_SEARCH'
export const FAILED_PRODUCT_VARIANTS_SEARCH = 'FAILED_PRODUCT_VARIANTS_SEARCH'
export const COMPLETED_PRODUCT_VARIANTS_SEARCH =
  'COMPLETED_PRODUCT_VARIANTS_SEARCH'
export const SORT_DATABASE = 'SORT_DATABASE'
export const PENDING_PRODUCT_REFRESH = 'PENDING_PRODUCT_REFRESH'
export const COMPLETED_PRODUCT_REFRESH = 'COMPLETED_PRODUCT_REFRESH'
export const PENDING_PRODUCT_VARIANT_REFRESH = 'PENDING_PRODUCT_VARIANT_REFRESH'
export const COMPLETED_PRODUCT_VARIANT_REFRESH =
  'COMPLETED_PRODUCT_VARIANT_REFRESH'
export const FAILED_PRODUCT_VARIANT_REFRESH = 'FAILED_PRODUCT_VARIANT_REFRESH'
export const PENDING_DATABASE_CSV_EXPORT = 'PENDING_DATABASE_CSV_EXPORT'
export const COMPLETED_DATABASE_CSV_EXPORT = 'COMPLETED_DATABASE_CSV_EXPORT'
export const FAILED_PRODUCT_REFRESH = 'FAILED_PRODUCT_REFRESH'
export const TOGGLE_DATABASE_DROPDOWN = 'TOGGLE_DATABASE_DROPDOWN'

export const SET_DATABASE_FILTER = 'SET_DATABASE_FILTER'
export const SET_DATE_FIRST_AVAILABLE_RANGE = 'SET_DATE_FIRST_AVAILABLE_RANGE'
export const SET_DATABASE_COUNTRY = 'SET_DATABASE_COUNTRY'
export const SET_DATABASE_SELECTED_CATEGORIES =
  'SET_DATABASE_SELECTED_CATEGORIES'
export const SET_DATABASE_PAGE = 'SET_DATABASE_PAGE'
export const EDIT_DATABASE_PAGE = 'EDIT_DATABASE_PAGE'
export const RESET_DATABASE_FILTERS = 'RESET_DATABASE_FILTERS'

export const PENDING_DATABASE_PRESET = 'PENDING_DATABASE_PRESET'
export const SET_DATABASE_PRESETS = 'SET_DATABASE_PRESETS'
export const LOAD_DATABASE_PRESET = 'LOAD_DATABASE_PRESET'
export const SELECT_DATABASE_PRESET = 'SELECT_DATABASE_PRESET'
export const UPDATE_DATABASE_PRESET_NAME = 'UPDATE_DATABASE_PRESET_NAME'
export const TOGGLE_MULTIUSER_SHARING = 'TOGGLE_MULTIUSER_SHARING'
export const TOGGLE_DATABASE_SAVE_PRESET = 'TOGGLE_DATABASE_SAVE_PRESET'
export const TOGGLE_DATABASE_LOAD_PRESET = 'TOGGLE_DATABASE_LOAD_PRESET'
export const TOGGLE_DATABASE_DELETE_PRESET = 'TOGGLE_DATABASE_DELETE_PRESET'

// Product Tracker Constants
export const PENDING_TRACKER_ACTION = 'PENDING_TRACKER_ACTION'
export const COMPLETED_TRACKER_ACTION = 'COMPLETED_TRACKER_ACTION'
export const RESET_TRACKER_ACTION = 'RESET_TRACKER_ACTION'

export const SET_TRACKER_DATA = 'SET_TRACKER_DATA'
export const REMOVE_TRACKER_NOTICE = 'REMOVE_TRACKER_NOTICE'
export const TOGGLE_REACTIVATE_FROM_TRACKER = 'TOGGLE_REACTIVATE_FROM_TRACKER'

export const CREATE_CATEGORY = 'CREATE_CATEGORY'
export const EDIT_CATEGORY = 'EDIT_CATEGORY'
export const DELETE_CATEGORY = 'DELETE_CATEGORY'

export const TRACKER_INPUT_FIELD_UPDATE = 'TRACKER_INPUT_FIELD_UPDATE'

export const SORT_CATEGORY = 'SORT_CATEGORY'
export const SELECT_DATE_RANGE = 'SELECT_DATE_RANGE'
export const SET_SELECTED_MONTHS = 'SET_SELECTED_MONTHS'

export const PENDING_ADD_PRODUCT_TRACK = 'PENDING_ADD_PRODUCT_TRACK'
export const COMPLETED_ADD_PRODUCT_TRACK = 'COMPLETED_ADD_PRODUCT_TRACK'
export const RESET_ADD_PRODUCT_TRACK = 'RESET_ADD_PRODUCT_TRACK'
export const ADD_PRODUCT_TO_CATEGORY = 'ADD_PRODUCT_TO_CATEGORY'

export const TOGGLE_DELETE_CONFIRMATION = 'TOGGLE_DELETE_CONFIRMATION'
export const DELETE_PRODUCT_TRACK = 'DELETE_PRODUCT_TRACK'
export const BATCH_DELETE_PRODUCT_TRACKS = 'BATCH_DELETE_PRODUCT_TRACKS'

export const BATCH_CATEGORIZE_PRODUCT_TRACKS = 'BATCH_CATEGORIZE_PRODUCT_TRACKS'

export const TOGGLE_CHECK_PRODUCT_TRACK = 'TOGGLE_CHECK_PRODUCT_TRACK'
export const TOGGLE_CHECK_ALL_PRODUCT_TRACKS = 'TOGGLE_CHECK_ALL_PRODUCT_TRACKS'
export const RESET_CHECKED_PRODUCTS = 'RESET_CHECKED_PRODUCTS'
export const TOGGLE_CHECK_ALL_RECOMMENDED_PRODUCTS =
  'TOGGLE_CHECK_ALL_RECOMMENDED_PRODUCTS'
export const RESET_CHECKED_RECOMMENDED_PRODUCTS =
  'RESET_CHECKED_RECOMMENDED_PRODUCTS'
export const TOGGLE_CHECK_RECOMMENDED_PRODUCT =
  'TOGGLE_CHECK_RECOMMENDED_PRODUCT'
export const SORT_RECOMMENDED_PRODUCTS = 'SORT_RECOMMENDED_PRODUCTS'

export const PENDING_AVERAGES = 'PENDING_AVERAGES'
export const FAILED_GET_TRACKER_CHART_DATA = 'FAILED_GET_TRACKER_CHART_DATA'
export const COMPLETED_GET_TRACKER_CHART_DATA =
  'COMPLETED_GET_TRACKER_CHART_DATA'
export const SELECT_CHART_TYPE = 'SELECT_CHART_TYPE'

export const SET_ACTIVE_TRACK_SIDECARD = 'SET_ACTIVE_TRACK_SIDECARD'
export const SET_SELECTED_TRACKER_NOTE = 'SET_SELECTED_TRACKER_NOTE'
export const UPDATE_TRACKER_NOTE_FIELD = 'UPDATE_TRACKER_NOTE_FIELD'
export const COMPLETED_LOAD_TRACKER_NOTES = 'COMPLETED_LOAD_TRACKER_NOTES'

export const PENDING_TRACKER_PRODUCT_SEARCH_PREVIEW =
  'PENDING_TRACKER_PRODUCT_SEARCH_PREVIEW'
export const COMPLETED_TRACKER_PRODUCT_SEARCH_PREVIEW =
  'COMPLETED_TRACKER_PRODUCT_SEARCH_PREVIEW'

// Global UI state action constants
export const PENDING_GLOBAL_ACTION = 'PENDING_GLOBAL_ACTION'
export const COMPLETED_GLOBAL_ACTION = 'COMPLETED_GLOBAL_ACTION'
export const RESET_GLOBAL_ACTION = 'RESET_GLOBAL_ACTION'

export const COMPLETED_ADD_LOCATION_HISTORY = 'COMPLETED_ADD_LOCATION_HISTORY'
export const LOAD_GLOBAL_DATA = 'LOAD_GLOBAL_DATA'
export const SET_GLOBAL_DATA = 'SET_GLOBAL_DATA'
export const SET_GLOBAL_MODAL = 'SET_GLOBAL_MODAL'
export const SET_GLOBAL_MODAL_METADATA = 'SET_GLOBAL_MODAL_METADATA'
export const UPDATE_PENDO = 'UPDATE_PENDO'

export const ADD_TO_GLOBAL_TRACKER = 'ADD_TO_GLOBAL_TRACKER'
export const REMOVE_FROM_GLOBAL_TRACKER = 'REMOVE_FROM_GLOBAL_TRACKER'

export const SET_NOTIFICATION = 'SET_NOTIFICATION'

export const SET_SUBSCRIPTION_INFO = 'SET_SUBSCRIPTION_INFO'

export const UPDATE_FEATURE_ACCESS = 'UPDATE_FEATURE_ACCESS'

export const TOGGLE_JSPUP_WARNING = 'TOGGLE_JSPUP_WARNING'

export const SET_SELLER_PLANS = 'SET_SELLER_PLANS'

export const TOGGLE_PROFIT_CALCULATOR = 'TOGGLE_PROFIT_CALCULATOR'
export const TOGGLE_VARIANT_MODAL = 'TOGGLE_VARIANT_MODAL'

// Chrome Extension Constants
export const PENDING_EXTENSION_ACTION = 'PENDING_EXTENSION_ACTION'
export const COMPLETED_EXTENSION_ACTION = 'COMPLETED_EXTENSION_ACTION'
export const RESET_EXTENSION_ACTION = 'RESET_EXTENSION_ACTION'

// For Address fields
export const ADDRESS_INPUT_FIELD_UPDATE = 'ADDRESS_INPUT_FIELD_UPDATE'
export const ADD_ADDRESS_FORM_VALIDATION_ERROR =
  'ADD_ADDRESS_FORM_VALIDATION_ERROR'

// Registrations Constants
export const REGISTRATION_INPUT_FIELD_UPDATE = 'REGISTRATION_INPUT_FIELD_UPDATE'
export const ADD_REGISTRATION_FORM_VALIDATION_ERROR =
  'ADD_REGISTRATION_FORM_VALIDATION_ERROR'

export const PENDING_REGISTRATION_ACTION = 'PENDING_REGISTRATION_ACTION'
export const COMPLETED_REGISTRATION_ACTION = 'COMPLETED_REGISTRATION_ACTION'
export const RESET_REGISTRATION_ACTION = 'RESET_REGISTRATION_ACTION'

export const SET_REGISTRATION_INFO = 'SET_REGISTRATION_INFO'
export const SET_REGISTRATION_UPSELL_INFO = 'SET_REGISTRATION_UPSELL_INFO'
export const CLEAR_REGISTRATION_INFO = 'CLEAR_REGISTRATION_INFO'
export const SET_REGISTRATION_EMAIL_STATUS = 'SET_REGISTRATION_EMAIL_STATUS'

export const LOAD_UPSELL_CONFIRMATION = 'LOAD_UPSELL_CONFIRMATION'
export const LOAD_UPSELL_TABLE = 'LOAD_UPSELL_TABLE'
export const SELECT_UPGRADE_OPTION = 'SELECT_UPGRADE_OPTION'
export const TOGGLE_UPSELL_CONFIRMATION = 'TOGGLE_UPSELL_CONFIRMATION'
export const UPDATE_ELIGIBLE = 'UPDATE_ELIGIBLE'
export const PENDING_EXISTING_USER_CHECKOUT_ACTION =
  'PENDING_EXISTING_USER_CHECKOUT_ACTION'
export const RESET_EXISTING_USER_CHECKOUT_ACTION =
  'RESET_EXISTING_USER_CHECKOUT_ACTION'
export const PENDING_EXISTING_USER_CHECKOUT_ACTION_WITH_NEW_PAYMENT =
  'PENDING_EXISTING_USER_CHECKOUT_ACTION_WITH_NEW_PAYMENT'
export const SET_RETRY_ALIPAY = 'SET_RETRY_ALIPAY'
export const SET_ERRORS_ALIPAY = 'SET_ERRORS_ALIPAY'

// Registrations ZH Constants
export const SET_CHINESE_CHECKOUT_STAGE = 'SET_CHINESE_CHECKOUT_STAGE'

// Sessions Constants
export const SESSION_INPUT_FIELD_UPDATE = 'SESSION_INPUT_FIELD_UPDATE'
export const ADD_SESSION_VALIDATION_ERROR = 'ADD_SESSION_VALIDATION_ERROR'
export const PENDING_SESSION_ACTION = 'PENDING_SESSION_ACTION'
export const COMPLETED_SESSION_ACTION = 'COMPLETED_SESSION_ACTION'
export const RESET_SESSION_ACTION = 'RESET_SESSION_ACTION'
export const TOGGLE_AUTHY_MODAL = 'TOGGLE_AUTHY_MODAL'
export const TOGGLE_LOGIN_MODAL = 'TOGGLE_LOGIN_MODAL'
export const LOADED_FEATURE_FLAGS = 'LOADED_FEATURE_FLAGS'

// ADMIN

// Base Plans
export const REPLACE_ADMIN_BASE_PLANS = 'REPLACE_ADMIN_BASE_PLANS'
export const PENDING_ADMIN_BASE_PLANS_ACTION = 'PENDING_ADMIN_BASE_PLANS_ACTION'
export const UPDATE_ADMIN_BASE_PLANS_PAGINATION =
  'UPDATE_ADMIN_BASE_PLANS_PAGINATION'
export const COMPLETED_ADMIN_BASE_PLANS_ACTION =
  'COMPLETED_ADMIN_BASE_PLANS_ACTION'
export const TOGGLE_ADMIN_BASE_PLANS_DELETE_MODAL =
  'TOGGLE_ADMIN_BASE_PLANS_DELETE_MODAL'
export const SORT_ADMIN_BASE_PLANS_TABLE = 'SORT_ADMIN_BASE_PLANS_TABLE'
export const UPDATE_ADMIN_BASE_PLANS_SELECTION =
  'UPDATE_ADMIN_BASE_PLANS_SELECTION'
export const TOGGLE_ADMIN_BASE_PLANS_FILTER_DROPDOWN =
  'TOGGLE_ADMIN_BASE_PLANS_FILTER_DROPDOWN'
export const UPDATE_ADMIN_BASE_PLANS_STATUS_FILTER =
  'UPDATE_ADMIN_BASE_PLANS_STATUS_FILTER'
export const COMPLETED_BASE_PLAN_SHOW = 'COMPLETED_BASE_PLAN_SHOW'
export const TOGGLE_ADMIN_BASE_PLANS_MODAL = 'TOGGLE_ADMIN_BASE_PLANS_MODAL'
export const CLOSE_ADMIN_BASE_PLAN_MODALS = 'CLOSE_ADMIN_BASE_PLAN_MODALS'
export const SET_CREATE_BASE_PLAN_STEP = 'SET_CREATE_BASE_PLAN_STEP'
export const SET_EDIT_BASE_PLAN_TAB = 'SET_EDIT_BASE_PLAN_TAB'
export const REQUEST_UPDATE_BASE_PLAN = 'REQUEST_UPDATE_BASE_PLAN'
export const RECEIVE_UPDATE_BASE_PLAN = 'RECEIVE_UPDATE_BASE_PLAN'
export const REQUEST_CREATE_BASE_PLAN = 'REQUEST_CREATE_BASE_PLAN'
export const RECEIVE_CREATE_BASE_PLAN = 'RECEIVE_CREATE_BASE_PLAN'
export const SET_BASE_PLAN_IS_CREATE_STEP_VALID =
  'SET_BASE_PLAN_IS_CREATE_STEP_VALID'
export const UPDATE_ADMIN_BASE_PLAN_FILTER = 'UPDATE_ADMIN_BASE_PLAN_FILTER'

// Campaigns
export const PENDING_ADMIN_CAMPAIGNS_ACTION = 'PENDING_ADMIN_CAMPAIGNS_ACTION'
export const COMPLETED_ADMIN_CAMPAIGNS_ACTION =
  'COMPLETED_ADMIN_CAMPAIGNS_ACTION'
export const COMPLETED_ADMIN_CAMPAIGNS_AND_BASE_PLANS_LOAD =
  'COMPLETED_ADMIN_CAMPAIGNS_AND_BASE_PLANS_LOAD'
export const REPLACE_ADMIN_CAMPAIGNS = 'REPLACE_ADMIN_CAMPAIGNS'
export const SORT_ADMIN_CAMPAIGNS_TABLE = 'SORT_ADMIN_CAMPAIGNS_TABLE'
export const UPDATE_ADMIN_CAMPAIGNS_SELECTION =
  'UPDATE_ADMIN_CAMPAIGNS_SELECTION'
export const UPDATE_ADMIN_CAMPAIGNS_FILTER = 'UPDATE_ADMIN_CAMPAIGNS_FILTER'

export const TOGGLE_ADMIN_CAMPAIGNS_FILTER_DROPDOWN =
  'TOGGLE_ADMIN_CAMPAIGNS_FILTER_DROPDOWN'
export const UPDATE_ADMIN_CAMPAIGNS_PAGINATION =
  'UPDATE_ADMIN_CAMPAIGNS_PAGINATION'
export const COMPLETED_CAMPAIGN_SHOW = 'COMPLETED_CAMPAIGN_SHOW'
export const TOGGLE_ADMIN_CAMPAIGNS_MODAL = 'TOGGLE_ADMIN_CAMPAIGNS_MODAL'
export const TOGGLE_CAMPAIGNS_EXPIRATION_REASON =
  'TOGGLE_CAMPAIGNS_EXPIRATION_REASON'
export const SET_CREATE_CAMPAIGN_STEP = 'SET_CREATE_CAMPAIGN_STEP'
export const SET_EDIT_CAMPAIGN_TAB = 'SET_EDIT_CAMPAIGN_TAB'
export const REQUEST_CREATE_CAMPAIGN = 'REQUEST_CREATE_CAMPAIGN'
export const RECEIVE_CREATE_CAMPAIGN = 'RECEIVE_CREATE_CAMPAIGN'
export const REQUEST_UPDATE_CAMPAIGN = 'REQUEST_UPDATE_CAMPAIGN'
export const RECEIVE_UPDATE_CAMPAIGN = 'RECEIVE_UPDATE_CAMPAIGN'
export const REQUEST_CAMPAIGN_BASE_PLAN_OPTIONS =
  'REQUEST_CAMPAIGN_BASE_PLAN_OPTIONS'
export const RECEIVE_CAMPAIGN_BASE_PLAN_OPTIONS =
  'RECEIVE_CAMPAIGN_BASE_PLAN_OPTIONS'
export const TOGGLE_ADMIN_CAMPAIGNS_DELETE_MODAL =
  'TOGGLE_ADMIN_CAMPAIGNS_DELETE_MODAL'
export const SET_CAMPAIGN_IS_CREATE_STEP_VALID =
  'SET_CAMPAIGN_IS_CREATE_STEP_VALID'

// Admin Users
export const PENDING_ADMIN_USER_ACTION = 'PENDING_ADMIN_USER_ACTION'
export const COMPLETED_ADMIN_USER_ACTION = 'COMPLETED_ADMIN_USER_ACTION'
export const RESET_ADMIN_USER_ACTION = 'RESET_ADMIN_USER_ACTION'

export const PENDING_ADMIN_JS_USERS_ACTION = 'PENDING_ADMIN_JS_USERS_ACTION'
export const COMPLETED_ADMIN_JS_USERS_ACTION = 'COMPLETED_ADMIN_JS_USERS_ACTION'
export const RESET_ADMIN_JS_USERS_ACTION = 'RESET_ADMIN_JS_USERS_ACTION'

export const COMPLETED_ADMIN_SEARCH_USERS = 'COMPLETED_ADMIN_SEARCH_USERS'
export const TOGGLE_EDIT_ADMIN_USER_EXTENSION =
  'TOGGLE_EDIT_ADMIN_USER_EXTENSION'
export const TOGGLE_EDIT_ADMIN_USER_MEMBERSHIP =
  'TOGGLE_EDIT_ADMIN_USER_MEMBERSHIP'

export const COMPLETED_ADMIN_USERS_TABLE_HEADER_SORT =
  'COMPLETED_ADMIN_USERS_TABLE_HEADER_SORT'
export const SET_ADMIN_USER_FILTER = 'SET_ADMIN_USER_FILTER'
export const SET_ADMIN_USER_PAGE = 'SET_ADMIN_USER_PAGE'
export const RESET_ADMIN_USER_FILTERS = 'RESET_ADMIN_USER_FILTERS'
export const COMPLETED_ADMIN_SHOW_USER = 'COMPLETED_ADMIN_SHOW_USER'
export const RESET_ADMIN_SELECTED_USER = 'RESET_ADMIN_SELECTED_USER'
export const RESET_ADMIN_SELECTED_USER_PRODUCT_INSTANCE_DATA =
  'RESET_ADMIN_SELECTED_USER_PRODUCT_INSTANCE_DATA'
export const UPDATE_ADMIN_SELECTED_USER = 'UPDATE_ADMIN_SELECTED_USER'
export const ADMIN_USER_INPUT_FIELD_UPDATE = 'ADMIN_USER_INPUT_FIELD_UPDATE'
export const COMPLETED_CREATE_USER_ADMIN_NOTE =
  'COMPLETED_CREATE_USER_ADMIN_NOTE'
export const UPDATE_USER_ADMIN_NOTE = 'UPDATE_USER_ADMIN_NOTE'

export const COMPLETED_ADMIN_USER_TEXT_AREA_CHANGE =
  'COMPLETED_ADMIN_USER_TEXT_AREA_CHANGE'
export const COMPLETED_SELECT_CHARGE_TO_REFUND =
  'COMPLETED_SELECT_CHARGE_TO_REFUND'
export const COMPLETED_SELECT_BALANCE_TRANSACTION =
  'COMPLETED_SELECT_BALANCE_TRANSACTION'
export const COMPLETED_CLOSE_CHARGES_MODAL = 'COMPLETED_CLOSE_CHARGES_MODAL'
export const COMPLETED_CLOSE_BALANCE_TRANSACTION_MODAL =
  'COMPLETED_CLOSE_BALANCE_TRANSACTION_MODAL'
export const COMPLETED_REFUND_INPUT_CHANGE = 'COMPLETED_REFUND_INPUT_CHANGE'
export const COMPLETED_BALANCE_TRANSACTION_INPUT_CHANGE =
  'COMPLETED_BALANCE_TRANSACTION_INPUT_CHANGE'
export const COMPLETED_SHOW_SUBSCRIPTION_RAW_DATA_MODAL =
  'COMPLETED_SHOW_SUBSCRIPTION_RAW_DATA_MODAL'
export const COMPLETED_CLOSE_SUBSCRIPTION_RAW_DATA_MODAL =
  'COMPLETED_CLOSE_SUBSCRIPTION_RAW_DATA_MODAL'
export const ADMIN_ACCESS_RIGHTS_INPUT_FIELD_UPDATE =
  'ADMIN_ACCESS_RIGHTS_INPUT_FIELD_UPDATE'
export const ADMIN_ADDITIONAL_USER_INPUT_FIELD_UPDATE =
  'ADMIN_ADDITIONAL_USER_INPUT_FIELD_UPDATE'
export const TOGGLE_ADMIN_USER_CONFIRMATION_MODAL =
  'TOGGLE_ADMIN_USER_CONFIRMATION_MODAL'
export const SET_ADMIN_USER_VENDOR_CENTRAL_EMAIL_INPUT =
  'SET_ADMIN_USER_VENDOR_CENTRAL_EMAIL_INPUT'

// // Dashboard
export const PENDING_ADMIN_DASHBOARD_ACTION = 'PENDING_ADMIN_DASHBOARD_ACTION'
export const COMPLETED_ADMIN_DASHBOARD_ACTION =
  'COMPLETED_ADMIN_DASHBOARD_ACTION'
export const RESET_ADMIN_DASHBOARD_ACTION = 'COMPLETED_ADMIN_DASHBOARD_ACTION'
export const COMPLETED_ADMIN_DASHBOARD_TOTALS_LOAD =
  'COMPLETED_ADMIN_DASHBOARD_TOTALS_LOAD'

// //Products
export const PENDING_ADMIN_PRODUCTS_ACTION = 'PENDING_ADMIN_PRODUCTS_ACTION'
export const COMPLETED_ADMIN_PRODUCTS_ACTION = 'COMPLETED_ADMIN_PRODUCTS_ACTION'
export const RESET_ADMIN_PRODUCTS_ACTION = 'RESET_ADMIN_PRODUCTS_ACTION'
export const COMPLETED_ADMIN_PRODUCTS_LOAD = 'COMPLETED_ADMIN_PRODUCTS_LOAD'
export const ADMIN_PRODUCTS_INPUT_FIELD_UPDATE =
  'ADMIN_PRODUCTS_INPUT_FIELD_UPDATE'
export const ADD_ADMIN_PRODUCTS_ERROR = 'ADD_ADMIN_PRODUCTS_ERROR'

// //Keywords
export const PENDING_ADMIN_KEYWORDS_ACTION = 'PENDING_ADMIN_KEYWORDS_ACTION'
export const COMPLETED_ADMIN_KEYWORDS_ACTION = 'COMPLETED_ADMIN_KEYWORDS_ACTION'
export const RESET_ADMIN_KEYWORDS_ACTION = 'RESET_ADMIN_KEYWORDS_ACTION'
export const COMPLETED_ADMIN_KEYWORDS_LOAD = 'COMPLETED_ADMIN_KEYWORDS_LOAD'
export const ADMIN_KEYWORDS_INPUT_FIELD_UPDATE =
  'ADMIN_KEYWORDS_INPUT_FIELD_UPDATE'
export const ADD_ADMIN_KEYWORDS_ERROR = 'ADD_ADMIN_KEYWORDS_ERROR'
export const TOGGLE_CHECK_ALL_KEYWORD_SCOUTS = 'TOGGLE_CHECK_ALL_KEYWORD_SCOUTS'
export const TOGGLE_KEYWORD_SELECTED = 'TOGGLE_KEYWORD_SELECTED'
export const RESET_KEYWORD_SELECTION = 'RESET_KEYWORD_SELECTION'
export const SET_INCLUDE_ALL_VARIANTS_TOGGLE = 'SET_INCLUDE_ALL_VARIANTS_TOGGLE'

// //Stripe Plans
export const COMPLETE_ADMIN_STRIPE_PLAN_LOAD = 'COMPLETE_ADMIN_STRIPE_PLAN_LOAD'

// //Keyword Lists
export const REQUEST_KEYWORD_LISTS = 'REQUEST_KEYWORD_LISTS'
export const RECEIVE_KEYWORD_LISTS = 'RECEIVE_KEYWORD_LISTS'
export const PENDING_KEYWORD_LIST_ACTION = 'PENDING_KEYWORD_LIST_ACTION'
export const COMPLETED_KEYWORD_LIST_ACTION = 'COMPLETED_KEYWORD_LIST_ACTION'
export const RESET_KEYWORD_LIST_ACTION = 'RESET_KEYWORD_LIST_ACTION'
export const KEYWORD_LIST_ITEM_ATTRIBUTE_CHANGE =
  'KEYWORD_LIST_ITEM_ATTRIBUTE_CHANGE'
export const RESET_KEYWORD_LIST_ITEM = 'RESET_KEYWORD_LIST_ITEM'
export const SET_KEYWORD_LIST_VALUE = 'SET_KEYWORD_LIST_VALUE'

export const SHOW_CHINESE_CHECKOUT_ERROR_PAGE =
  'SHOW_CHINESE_CHECKOUT_ERROR_PAGE'

// Segment Builder
export const SET_SEGMENT_BUILDER_DATA = 'SET_SEGMENT_BUILDER_DATA'

export * from './action_types/product_alerts'
export * from './action_types/global'

// Keywords
export * from './action_types/keywords/keyword_engine'

// Admin
export * from './action_types/admin/users'
export * from './action_types/admin/stripe_coupons'

// Historical Data
export * from './action_types/historical_data/search_volume'
export * from './action_types/historical_data/average_prices'
export * from './action_types/historical_data/units_sold'
export * from './action_types/historical_data/seasonality'

// Settings
export * from './action_types/settings/alert_settings'
export * from './action_types/settings/email_settings'

// Market Insights
export * from './action_types/market_insights/categories'
export * from './action_types/market_insights/segments'
export * from './action_types/market_insights/share_and_trends'

// Product Insights
export * from './action_types/product_insights/asin_explorer'

// Seats
export * from './action_types/seats'

export * from './action_types/chatbot'
