import React from 'react'
import { Translation } from 'react-i18next'
import { MWS_SYNC_DATA_ATTRIBUTE_IDS } from 'constants/data_attributes/account/mwsSync'

export const AMAZON_SELLER_ACCOUNT_MODALS = {
  accountDetails: 'accountDetails',
  deleteAccount: 'deleteAccount',
  enableMWSToken: 'enableMWSToken',
  enablePPC: 'enablePPC',
  ppcTutorial: 'ppcTutorial',
  ppcSyncSuccess: 'ppcSyncSuccess'
}

export const AMAZON_MWS_PPC_SYNC_STEPS = {
  unlockFeaturesModal: 'unlockFeaturesModal',
  introduction: 'introduction',
  tutorialModal: 'tutorialModal',
  selectRegion: 'selectRegion',
  accountSync: 'accountSync',
  ppcSync: 'ppcSync',
  accountSetup: 'accountSetup',
  congratulations: 'congratulations'
}

export const MWS_PPC_SYNC_STEPS = [
  {
    label: (
      <Translation ns="constants">
        {t =>
          t('constants:account.MWS_PPC_SYNC_STEPS.marketplace', 'Marketplace')
        }
      </Translation>
    ),
    modal: AMAZON_MWS_PPC_SYNC_STEPS.selectRegion,
    order: 0
  },
  {
    label: (
      <Translation ns="constants">
        {t =>
          t('constants:account.MWS_PPC_SYNC_STEPS.accountSync', 'Account Sync')
        }
      </Translation>
    ),
    modal: AMAZON_MWS_PPC_SYNC_STEPS.accountSync,
    order: 1
  },
  {
    label: (
      <Translation ns="constants">
        {t => t('constants:account.MWS_PPC_SYNC_STEPS.ppcSync', 'PPC Sync')}
      </Translation>
    ),
    modal: AMAZON_MWS_PPC_SYNC_STEPS.ppcSync,
    order: 2
  },
  {
    label: (
      <Translation ns="constants">
        {t =>
          t(
            'constants:account.MWS_PPC_SYNC_STEPS.accountSetup',
            'Account Setup'
          )
        }
      </Translation>
    ),
    modal: AMAZON_MWS_PPC_SYNC_STEPS.accountSetup,
    order: 3
  }
]

export const PPC_SYNC_STATUS = {
  initial: 'initial',
  success: 'success',
  failure: 'failure'
}

export const MWS_SYNC_STATUS = {
  initial: 'initial',
  success: 'success',
  failure: 'failure'
}

export const MWS_MARKETPLACE_DATA_IDS = {
  US: MWS_SYNC_DATA_ATTRIBUTE_IDS.Dropdowns.MwsModal.MwsPrimaryMarketplaceUS,
  CA: MWS_SYNC_DATA_ATTRIBUTE_IDS.Dropdowns.MwsModal.MwsPrimaryMarketplaceCA,
  MX: MWS_SYNC_DATA_ATTRIBUTE_IDS.Dropdowns.MwsModal.MwsPrimaryMarketplaceMX,
  UK: MWS_SYNC_DATA_ATTRIBUTE_IDS.Dropdowns.MwsModal.MwsPrimaryMarketplaceUK,
  FR: MWS_SYNC_DATA_ATTRIBUTE_IDS.Dropdowns.MwsModal.MwsPrimaryMarketplaceFR,
  BE: MWS_SYNC_DATA_ATTRIBUTE_IDS.Dropdowns.MwsModal.MwsPrimaryMarketplaceBE,
  DE: MWS_SYNC_DATA_ATTRIBUTE_IDS.Dropdowns.MwsModal.MwsPrimaryMarketplaceDE,
  IT: MWS_SYNC_DATA_ATTRIBUTE_IDS.Dropdowns.MwsModal.MwsPrimaryMarketplaceIT,
  ES: MWS_SYNC_DATA_ATTRIBUTE_IDS.Dropdowns.MwsModal.MwsPrimaryMarketplaceES,
  JP: MWS_SYNC_DATA_ATTRIBUTE_IDS.Dropdowns.MwsModal.MwsPrimaryMarketplaceJP,
  AU: MWS_SYNC_DATA_ATTRIBUTE_IDS.Dropdowns.MwsModal.MwsPrimaryMarketplaceAU,
  SG: MWS_SYNC_DATA_ATTRIBUTE_IDS.Dropdowns.MwsModal.MwsPrimaryMarketplaceSG
}

export const LOCAL_STORAGE_KEYS = {
  MWS_SYNC_REDIRECT_STATE: 'MWS_SYNC_REDIRECT_STATE',
  PPC_SYNC_REDIRECT_STATE: 'PPC_SYNC_REDIRECT_STATE'
}

export const USD_CURRENCY_CODE = 'usd'
export const EUR_CURRENCY_CODE = 'eur'
export const INR_CURRENCY_CODE = 'inr'
export const JPY_CURRENCY_CODE = 'jpy'

export const CN_CHECKOUT_LOCALE = 'CN'

export const CHINESE_LANGUAGE_CHECKOUT_LOCALES = [
  CN_CHECKOUT_LOCALE,
  'TW',
  'MO',
  'HK'
]

export const USD_PRICING_PAGE_URL = 'https://www.junglescout.com/pricing'
export const USD_TEST_PRICING_PAGE_URL = 'https://www.junglescout.com/pricing'
export const CN_PRICING_PAGE_URL =
  'https://www.junglescout.cn/upgrade-plan-page'
export const DE_EUR_PRICING_PAGE_URL = 'https://www.junglescout.com/de/pricing'
export const ES_EUR_PRICING_PAGE_URL = 'https://www.junglescout.com/es/pricing'
export const FR_EUR_PRICING_PAGE_URL = 'https://www.junglescout.com/fr/pricing'
export const IT_EUR_PRICING_PAGE_URL = 'https://www.junglescout.com/it/pricing'
export const INR_PRICING_PAGE_URL = 'https://www.junglescout.com/in/pricing'
export const JPY_PRICING_PAGE_URL = 'https://www.junglescout.com/jp/pricing'

export const ACCOUNT_ADD_USER_MODAL = 'AccountAddUserModal'
export const NOTIFY_EXPIRING_CARD_MODAL = 'NotifyExpiringCardModal'
export const EXPIRING_CARD_LAST_NOTIFIED = 'expiringCardLastNotified'
export const FEATURE_LIMIT_MODAL = 'FeatureLimitModal'
export const NO_PLAN_MODAL = 'NoPlanModal'

export const INVALID_EMAIL_MESSAGE = (
  <Translation ns="sessions">
    {t =>
      t(
        'sessions:LoginForm.email.invalidEmailMessage',
        'Please enter a valid email format.'
      )
    }
  </Translation>
)

export const ACCOUNT_SETTINGS_ICONS = {
  account: 'USER',
  billing: 'CREDIT_CARD',
  subscriptions: 'LAYERS',
  email: 'MAIL',
  alerts: 'BELL',
  amazon: 'AMAZON_CIRCLE',
  team: 'USERS',
  languages: 'TRANSLATE'
}
